<template>
  <div>
    <input class="mb-1 le-input-builder" :class="{ ['error-border']: !block.data.value }" v-model.trim="block.data.value" type="text" />
    <SSOFieldTags :index="index" :block="block" @click="(...args) => $emit('update', ...args)" />
  </div>
</template>

<script>
import SSOFieldTags from '../SSOFieldTags';
export default {
  name: 'BlockValue',
  props: ['index', 'block'],
  components: { SSOFieldTags },
  data() {
    return {};
  },
};
</script>
