import BlockValue from './BlockValue';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('builder');

const defaultValueBlock = {
  name: {
    placeholder: 'Name',
    value: 'name',
  },
  phone: {
    value: 'phone',
  },
  email: {
    placeholder: 'Email',
    value: 'email',
  },
  birthday: {
    placeholder: 'Birthday',
    value: 'birthday',
  },
  gender: {
    placeholder: 'Gender',
    value: 'gender',
    listQuestion: [
      { placeholder: 'Male', value: '1' },
      { placeholder: 'Female', value: '0' },
    ],
  },
};


export const blockMixin = {
  components: { BlockValue },
  computed: {
    ...mapState(['currentSelectEdit']),
  },
  methods: {
    ...mapMutations(['SET_DATA']),
    emitUpdate(value, index, block) {
      const newBlock = { ...block, data: { ...block.data, ...defaultValueBlock[value] } };
      if (['PhoneBlock', 'PhoneOtpBlock'].includes(block.name)) {
        newBlock.data.placeholder = 'Phone';
      }
      this.$emit('update', index, newBlock);
    },
    emitSelect() {
      console.log('emitSelect', this.portalID);
      this.SET_DATA({ key: 'currentSelectEdit', value: this.portalID });
      this.$forceUpdate();
    },
    emitRemove(isThankyou) {
      console.log('remove', this.index);
      this.SET_DATA({ key: 'currentSelectEdit', value: '' });
      this.$emit('remove', this.index, isThankyou);
    },
    emitHide(name) {
      this.$emit('hideblock', name);
    },
  },
};
