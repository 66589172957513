import Api from '@/api';
import { API_PATH } from '@/constants/api-constants';
import { SITES, SITE_DOMAINS } from '@/constants/site';
import { mapGetters } from 'vuex';

const APP_STORE_MUTATION = {
  SET_DATA: 'SET_DATA',
};
const APP_STORE_INIT = {
  loading: false,
  listSites: [],
};
const appStore = {
  namespaced: true,
  state: {
    ...APP_STORE_INIT,
  },
  mutations: {
    reset(state) {
      Object.assign(state, { ...APP_STORE_INIT });
    },
  },
  actions: {
    fetchListSites({ commit, state }) {
      const { loading, listSites } = state;
      state.loading = true;
      const { sites: userDataSites = [] } = this.state.auth.userData || {};
      if (listSites.length || loading) {
        return;
      }
      Api.get(API_PATH.SITES)
        .then(({ data }) => {
          const { _data } = data || {};
          const { models } = _data || {};
          const sitesMapped = models
            ?.map((site) => {
              const { name } = site;
              if (SITES?.[SITE_DOMAINS?.[name]]) {
                return { ...site, ...SITES[SITE_DOMAINS[name]] };
              }
              return { ...site };
            })
            .filter(({ id: siteId, name }) => {
              if (!userDataSites?.length) {
                return false;
              }
              return userDataSites.some((userSite) => userSite.hostname === name);
            });
          state.listSites = sitesMapped;
        })
        .catch((error) => {
          console.log('** appStore/fetchListSites error: ', error);
        })
        .finally(() => {
          state.loading = false;
        });
    },
  },
};
export default appStore;
