import { API_STATUS_CODE, API_TOKEN } from '@/constants/api-constants';
import { LOCALSTORAGE } from '@/constants/app-constants';
import { ROUTER } from '@/constants/router-constant';
import app from '@/main';
import router from '@/router';

/**
 *
 * https://github.com/axios/axios#cancellation
 * @param {*} error
 */
export default function afterResponseError(error) {
  const { response, config, message: errorMessage, request } = error;
  const { hideError } = config;
  const { data, status } = response;
  const { message: dataMessage, _messages: dataMessages, errors: dataErrors } = data;

  app.$screenloading(false);
  if (!hideError) {
    const message = dataMessage || errorMessage || dataMessages[0] || 'Something wrong!';
    if (status === API_STATUS_CODE.CODE_403) {
      localStorage.removeItem(API_TOKEN.ACCESS_TOKEN);
      localStorage.removeItem(LOCALSTORAGE.USER);
      router.go(ROUTER.LOGIN);
    }

		if (Object.keys(dataErrors).length) {
      Object.keys(dataErrors).map((errorKey) => {
        const dataErrorMessages = dataErrors[errorKey];
        if (dataErrorMessages.length) {
          app.$notify({
            title: message,
            text: `${errorKey}: ${dataErrorMessages.join(';')}`,
            type: 'error',
          });
        }
      });
		} else{
			app.$notify({
        title: message,
        text: 'Please try again or contact admin!',
        type: 'error',
      });
		}
  }
  return Promise.reject(error);
}
