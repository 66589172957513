<template>
	<div class="ssoField">
		<a-tooltip v-for="item in listSsoField" :title="`Map ${item} field of user information to this block`" placement="leftTop">
			<a-tag  @click="() => $emit('click', item, index, block)" class="ssoField__tag">{{ item }}</a-tag>
		</a-tooltip>
		</div>
</template>

<script>
const listSSOFieldByBlock = {
  TextBlock: ['name'],
  EmailBlock: ['email'],
  DateBlock: ['birthday'],
  PhoneBlock: ['phone'],
  PhoneOtpBlock: ['phone'],
  WhatsappOtpBlock: ['phone'],
  ZaloOtpBlock: ['phone'],
  RadioBlock: ['gender'],
  DropdownBlock: ['gender'],
};

export default {
  name: 'SSOFieldTags',
  props: ['index', 'block'],
  data() {
    return {};
  },
	computed: {
		listSsoField: {
			get({ block }) {
				return block.name in listSSOFieldByBlock ? listSSOFieldByBlock[block.name] : []
			}
		}
	}
};
</script>
<style lang="scss">
.ssoField {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  &__tag {
    &.ant-tag {
      margin: 0;
      cursor: pointer;
    }
  }
}
</style>
