export const API_PATH = {
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  RESET_EMAIL_PASSWORD: '/api/password/email',
  RESET_PASSWORD: '/api/password/reset',
  CAMPAIGN: '/api/admin/campaign',
  CREATE_CAMPAIGN: '/api/admin/campaign',
  LIST_CAMPAIGN: '/api/admin/campaign',
  ACCOUNT_INFO: '/api/admin/account',
  SAVE_ACCOUNT_DETAILS: '/api/admin/account',
  SAVE_ACCOUNT_PASSWORD: '/api/admin/account/password',
  USERS: '/api/admin/users',
  ROLES: '/api/admin/roles',
  SITES: '/api/admin/sites',
  LIST_HISTORY: '/api/admin/history',
  CLIENTS: '/api/admin/clients',
  CSV_DOWNLOAD_SAMPLE: '/api/admin/campaign/csv/sample',
  CSV_VALIDATION: '/api/admin/campaign/validation',
};

export const API_TOKEN = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
};

export const API_STATUS = {
  SUCCESS: 1,
  ERROR: -1,
};

export const API_STATUS_CODE = {
  CODE_403: 403,
};

export const API_METHOD = {
  PUT: 'put',
  POST: 'post',
};
